import React from 'react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider, Text } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { StaticRouter } from "react-router-dom/server";
import { useEffect } from 'react';


// LOAD CSS HERE
import './css/app.css'
import 'material-icons/iconfont/material-icons.css';
import '@mantine/core/styles.css';



const Render = ({url}) => {
  useEffect(() => {
    // Load css files that should only be rendered on client. These are located in the public folder
    if (typeof window !== 'undefined') {
      loadCSS('/css/vivify.min.css');
    }
  }, []);
  if(typeof window == 'undefined'){
    return (
      <MantineProvider withGlobalStyles withNormalizeCSS >
        <StaticRouter location={url}>
          <Notifications />
          <App />
        </StaticRouter>
    </MantineProvider>
    )
  }
  else{
    return (
    <MantineProvider withGlobalStyles withNormalizeCSS >

      <BrowserRouter>
        <Notifications />
        <App />
      </BrowserRouter>
  </MantineProvider>
    )
  }

 

}

function loadCSS(href) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  document.head.appendChild(link);
}

export default Render