import React, { useState, useEffect } from 'react';

const LandingPage = () => {
    // State to handle form inputs and activities
    const [title, setTitle] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [activityStyle, setActivityStyle] = useState('');
    const [activities, setActivities] = useState([]);
    const [formComplete, setFormComplete] = useState(false);

    // Effect to load activities from local storage on component mount
    useEffect(() => {
        const storedActivities = JSON.parse(localStorage.getItem('activities')) || [];
        setActivities(storedActivities);
    }, []);

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        const newActivity = { title, websiteUrl, activityStyle };

        // Add new activity to the list and local storage
        const updatedActivities = [...activities, newActivity];
        setActivities(updatedActivities);
        localStorage.setItem('activities', JSON.stringify(updatedActivities));

        // Reset form fields
        setTitle('');
        setWebsiteUrl('');
        setActivityStyle('');

        // Set form complete state
        setFormComplete(true);
    };

    return (
        <div className="form-container">
            {formComplete ? (
                <div className="form-complete">
                    <h2>Form Complete</h2>
                    <label>
                        <i className='material-icons'>
                            check_circle
                        </i>
                        Activity Created Successfully
                    </label>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <label htmlFor="title">Title</label>
                    <input
                        type="text"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Enter Title"
                        required
                    />

                    <label htmlFor="websiteUrl">Website URL</label>
                    <input
                        type="url"
                        id="websiteUrl"
                        value={websiteUrl}
                        onChange={(e) => setWebsiteUrl(e.target.value)}
                        placeholder="Enter Website URL"
                        required
                    />

                    <label htmlFor="activityStyle">Activity Style</label>
                    <select
                        id="activityStyle"
                        value={activityStyle}
                        onChange={(e) => setActivityStyle(e.target.value)}
                        required
                    >
                        <option value="" disabled>
                            Select Activity Style
                        </option>
                        <option value="Default">Default</option>
                        <option value="Compact">Compact</option>
                        <option value="Expanded">Expanded</option>
                    </select>

                    <button type="submit">Create Activity</button>
                </form>
            )}
        </div>
    );
};

export default LandingPage;
