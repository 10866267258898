import React, { useState, useEffect } from 'react';

const WebActivity = () => {
    const [title, setTitle] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [activityStyle, setActivityStyle] = useState('');
    const [activities, setActivities] = useState([]);
    const [formComplete, setFormComplete] = useState(false);

    useEffect(() => {
        const storedActivities = JSON.parse(localStorage.getItem('activities')) || [];
        setActivities(storedActivities);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const newActivity = { title, websiteUrl, activityStyle };
        const updatedActivities = [...activities, newActivity];
        setActivities(updatedActivities);
        localStorage.setItem('activities', JSON.stringify(updatedActivities));
        setTitle('');
        setWebsiteUrl('');
        setActivityStyle('');
        setFormComplete(true);
    };

    // Copy text to clipboard function with iOS fallback
    const copyToClipboard = async (text) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            try {
                await navigator.clipboard.writeText(text);
                alert('Code copied to clipboard!');
            } catch (err) {
                console.error('Failed to copy: ', err);
            }
        } else {
            // Fallback for older iOS versions
            const textArea = document.createElement("textarea");
            textArea.value = text;
            textArea.style.position = "fixed";
            textArea.style.opacity = "0";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand("copy");
                alert('Code copied to clipboard!');
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textArea);
        }
    };

    return (
        <div className="form-container">
            {/* List of Stored Activities */}
            <div className="activity-list">
                {activities.length === 0 ? (
                    <p>No activities created.</p>
                ) : (
                    <ul>
                        {activities.map((activity, index) => (
                            <li key={index}>
                                <strong>{activity.title}</strong> - {activity.websiteUrl} ({activity.activityStyle})

                                {/* Copy code snippet to clipboard */}
                                <button style={{
                                    width: '100%'
                                }} onClick={() => {
                                    const codeSnippet =
                                        // random number generator
                                        `Math.floor(Math.random() * 100) + 1;`;
                                    ;
                                    copyToClipboard(codeSnippet);
                                }}>Copy Code</button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default WebActivity;
